import html2canvas from "html2canvas";
export function downloadHtml2canvas(node,title,config){
  html2canvas(node,{
    ...config// 配置项
  }).then((canvas) => {
    //回调中返回的其实就是一个canvas对象
    //可以使用canvas的toDataURL方法生成图片链接
    //url就是生成的链接可直接写入image标签的src中展示
    let url = canvas.toDataURL("image/png");
    // 创建一个a标签
    let link = document.createElement("a");
    //将生成的图片url赋值给a标签的href属性
    link.href = url;
    //设置下载的文件名
    link.download = title;
    // 将a标签插入dom中
    document.body.appendChild(link);
    //模拟点击事件触发下载
    link.click();
    // 完成之后销毁创建的a标签
    document.body.removeChild(link);
  });
}